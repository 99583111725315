.legend {
  background-color: #f3f4f6;
  padding: 7px;
  border-radius: 7px;
  width: 250px;
}

.gradiant {
  background: linear-gradient(
    to right,
    rgba(130, 22, 146, 1) 10%,
    rgba(130, 87, 219, 1) 10% 20%,
    rgba(32, 140, 236, 1) 20% 30%,
    rgba(32, 196, 232, 1) 40% 50%,
    rgba(35, 221, 221, 1) 50% 60%,
    rgba(194, 255, 40, 1) 60% 70%,
    rgba(255, 240, 40, 1) 70% 80%,
    rgba(255, 194, 40, 1) 80% 90%,
    rgba(252, 128, 20, 1) 90%
  );
  height: 10px;
  width: 235px;
}

.flex {
  display: flex;
}

.flex-item {
  justify-content: space-between;
}
