.custom-marker-cluster-green {
  background: #008001;
  border: 3px solid #ededed;
  border-radius: 50%;
  color: #ededed;
  height: 40px;
  line-height: 28px;
  text-align: center;
  width: 40px;
}

.custom-marker-cluster-red {
  background: #FF0000;
  border: 3px solid #ededed;
  border-radius: 50%;
  color: #ededed;
  height: 40px;
  line-height: 28px;
  text-align: center;
  width: 40px;
}

.leaflet-popup-content {
//width: auto!important;
}

.leaflet-control-attribution {
  display: none;
}